import React, {useEffect, useState} from 'react';
import './main.css';
import UtcClock from "../components/utc-clock";
import WorldClocks from "../components/world-clocks";
import EpochConverters from "../components/epoch-converters";
import LocalToUtc from "../components/local-to-utc";

function MainPage() {
    const [currentTime, setCurrentTime] = useState(Math.floor(new Date().getTime() / 1000));

    useEffect(() => {
        const interval = setInterval(() => {
            const current = new Date();
            setCurrentTime(Math.floor(current.getTime() / 1000));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="App">
            <WorldClocks currentUtcTime={currentTime}/>
            <UtcClock currentUtcTime={currentTime}/>
            <div className="EpochAndLocalToUtc">
                <EpochConverters currentUtcTime={currentTime}/>
                <LocalToUtc currentUtcTime={currentTime}/>
            </div>
        </div>
    );
}

export default MainPage;
