import React from 'react';
import './world-clocks.css';
import WorldClock from "./world-clock";

interface WorldClocksProps {
    currentUtcTime: number;
}

function WorldClocks(props: WorldClocksProps) {
    const zones = [
        // {name: 'Anchorage', zone: 'America/Anchorage', zoneStandardCode: 'AKST', offsetSummer: -8, offsetStandard: -9},
        {name: 'Los Angeles', zone: 'America/Los_Angeles', zoneStandardCode: 'PST', offsetSummer: -7, offsetStandard: -8},
        {name: 'Denver', zone: 'America/Denver', zoneStandardCode: 'MST', offsetSummer: -6, offsetStandard: -7},
        {name: 'Chicago', zone: 'America/Chicago', zoneStandardCode: 'CST', offsetSummer: -5, offsetStandard: -6},
        {name: 'New York', zone: 'America/New_York', zoneStandardCode: 'EST', offsetSummer: -4, offsetStandard: -5},
        // {name: 'Reykjavik', zone: 'Atlantic/Reykjavik', zoneStandardCode: 'GMT', offsetSummer: 0, offsetStandard: 0},
        {name: 'London', zone: 'Europe/London', zoneStandardCode: 'GMT', offsetSummer: 1, offsetStandard: 0},
        {name: 'Amsterdam', zone: 'Europe/Amsterdam', zoneStandardCode: 'CET', offsetSummer: 2, offsetStandard: 1},
        {name: 'Cape Town', zone: 'Africa/Johannesburg', zoneStandardCode: 'SAT', offsetSummer: 2, offsetStandard: 2},
        // {name: 'Helsinki', zone: 'Europe/Helsinki', zoneStandardCode: 'EET', offsetSummer: 3, offsetStandard: 2},
        // {name: 'Moscow', zone: 'Europe/Moscow', zoneStandardCode: 'EET', offsetSummer: 3, offsetStandard: 3},
        {name: 'Yekaterinburg', zone: 'Asia/Yekaterinburg', zoneStandardCode: 'YEKT', offsetSummer: 5, offsetStandard: 5},
        // {name: 'Kolkata', zone: 'Asia/Kolkata', zoneStandardCode: 'IST', offsetSummer: 5.5, offsetStandard: 5.5},
        // {name: 'Bangkok', zone: 'Asia/Bangkok', zoneStandardCode: 'ICT', offsetSummer: 7, offsetStandard: 7},
        {name: 'Shanghai', zone: 'Asia/Shanghai', zoneStandardCode: 'CST', offsetSummer: 8, offsetStandard: 8},
        // {name: 'Tokyo', zone: 'Asia/Tokyo', zoneStandardCode: 'JST', offsetSummer: 9, offsetStandard: 9},
    ];
    return (
        <div className="WorldClocks">
            {props.currentUtcTime && zones.map(c =>
                <WorldClock key={c.name} name={c.name} currentUtcTime={props.currentUtcTime} offset={3600 * c.offsetSummer} zone={c.zone}/>
            )}
        </div>
    );
}

export default WorldClocks;