import React from "react";
import './epoch-converters.css';

interface EpochConvertersProps {
    currentUtcTime: number;
}

function EpochConverters(props: EpochConvertersProps) {
    const secondsInMinute = 60;
    const secondsInHour = secondsInMinute * 60;
    const secondsInDay = secondsInHour * 24;
    return (
        <div className="EpochConverters">
            <div className="EpochConverter">
                <div className="EpochConverterName">Epoch now:</div>
                <div className="EpochConverterValue">{props.currentUtcTime}</div>
            </div>
            <div className="EpochConverter">
                <div className="EpochConverterName">Epoch minute:</div>
                <div className="EpochConverterValue">{Math.floor(props.currentUtcTime / secondsInMinute) * secondsInMinute}</div>
            </div>
            <div className="EpochConverter">
                <div className="EpochConverterName">Epoch hour:</div>
                <div className="EpochConverterValue">{Math.floor(props.currentUtcTime / secondsInHour) * secondsInHour}</div>
            </div>
            <div className="EpochConverter">
                <div className="EpochConverterName">Epoch day:</div>
                <div className="EpochConverterValue">{Math.floor(props.currentUtcTime / secondsInDay) * secondsInDay}</div>
            </div>
        </div>
    );
}

export default EpochConverters;