import React from 'react';
import './utc-clock.css';
import {dateFormatUtc, timeFormatUtc} from "../utils/utils";

interface UtcClockProps {
    currentUtcTime: number;
}

function UtcClock(props: UtcClockProps) {
    const dt = new Date(props.currentUtcTime * 1000);
    const utcTime = timeFormatUtc(dt);
    const utcDate = dateFormatUtc(dt);
    return (
        <div className="UtcClock">
            <div className="UtcTime">{utcTime}</div>
            <div className="UtcDate">{utcDate}</div>
        </div>
    );
}

export default UtcClock;