import React from "react";
import './local-to-utc.css';

interface LocalToUtcProps {
    currentUtcTime: number;
}

function convertToUtc(hour: number, localTz: string) {
    const localTime = new Date();
    localTime.setHours(hour);
    const utcTime = new Date(localTime.toLocaleString('en-US', {timeZone: localTz}));
    return utcTime.getUTCHours();
}

function LocalToUtc(props: LocalToUtcProps) {
    const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    return (
        <div className="LocalToUtcHours">
            <div className="LocalToUtcHour">
                <div className="LocalHour">Local time</div>
                <div className="UtcHour">UTC time</div>
            </div>
            {localHours.map(hour =>
                <div className="LocalToUtcHour">
                    <div className="LocalHour">{hour}</div>
                    <div className="UtcHour">{convertToUtc(hour, localTz)}</div>
                </div>
            )}
        </div>
    );
}

export default LocalToUtc;