import React from "react";
import './world-clock.css';
import {dateFormat, timeFormat} from "../utils/utils";

interface WorldClockProps {
    currentUtcTime: number;
    name: string;
    offset: number;
    zone: string;
}

function WorldClock(props: WorldClockProps) {
    console.log('WorldClock', props);
    const timeInTz = props.currentUtcTime;
    return (
        <div className="WorldClock">
            <div className="ClockName">{props.name}</div>
            <div className="ClockTime">{`${timeFormat(new Date(timeInTz*1000), props.zone)}`}</div>
            <div className="ClockDate">{`${dateFormat(new Date(timeInTz*1000), props.zone)}`}</div>
        </div>
    );
}

export default WorldClock;