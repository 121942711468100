const pad = (v: any) => `0${v}`.slice(-2);

export function dateFormatUtc(d: Date): string {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const month = months[d.getUTCMonth()];
    const day = weekdays[d.getUTCDay()];
    // Saturday, June 1, 2000
    return `${day}, ${month} ${pad(d.getUTCDate())}, ${d.getUTCFullYear()}`;
}

export function timeFormatUtc(d: Date): string {
    // 13:01:02 UTC
    return `${pad(d.getUTCHours())}:${pad(d.getUTCMinutes())}:${pad(d.getUTCSeconds())} UTC`;
}

export function dateFormat(d: Date, tz: string): string {
    const dateInTz = new Date(d.toLocaleString("en-US", {timeZone: tz}));
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const month = months[dateInTz.getMonth()];
    const day = weekdays[dateInTz.getDay()];
    // Saturday, June 1, 2000
    return `${day}, ${month} ${pad(dateInTz.getDate())}, ${dateInTz.getFullYear()}`;
}

export function timeFormat(d: Date, tz: string): string {
    const dateInTz = new Date(d.toLocaleString("en-US", {timeZone: tz}));
    // 13:01:02 UTC
    return `${pad(dateInTz.getHours())}:${pad(dateInTz.getMinutes())}:${pad(dateInTz.getSeconds())}`;
}